import { useApi } from "@backstage/core-plugin-api";
import { catalogApiRef } from "@backstage/plugin-catalog-react";
import { TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FieldProps, FieldValidation, UiSchema } from "@rjsf/utils";
import React, { useCallback, useEffect, useState } from "react";
// import InputLabel from '@mui/material/InputLabel';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import FormHelperText from '@mui/material/FormHelperText';


export const allowArbitraryValues = (uiSchema: UiSchema): boolean =>
  (uiSchema["ui:options"]?.allowArbitraryValues as boolean) ?? true;

export const EksVPCRegionSubnetExtension = ({
  required,
  rawErrors,
  formData,
  idSchema,
  onChange
}: FieldProps<string>) => {
  const catalogApi = useApi(catalogApiRef);
  const [vpcEntityData, setVPCEntityData] = useState(null);
  const [subnetEntityData, setSubnetEntityData] = useState(null);
  const [hostedZoneEntityData, setHostedZoneEntityData] = useState(null);
  
  const [entityVPCRefs, setEntityVPCRefs] = useState<{ groupByRegion: string; label: string; value: string; regionShortName: string; cidrBlock: string; }[]>([]);
  const [entitySubnetRefs, setEntitySubnetRefs] = useState<{ groupByType: string; label: string; value: string; cidrBlock:String; az:string; azId:string; azRegion:string;}[]>([]);
  const [entityHostedZoneRefs, setEntityHostedZoneRefs] = useState<{groupByType: string; label: string; value: string;}[]>([]);
  const [entityAtmosRefs, setEntityAtmosRefs] = useState<{ name: string; accountId: string;}[]>([]);
  
  const [hasAtmosAccountChanged, setHasAtmosAccountChanged] = useState(false);
  const [hasVPCChanged, setHasVPCChanged] = useState(false);
  
  const [selecteAtmosAccount, setSelecteAtmosAccount] = useState({ name: "", accountId: "" });
  const [selectedVPC, setSelectedVPC] = useState({groupByRegion:'', label:'', value:'', regionShortName: '', cidrBlock:''});
  const [selectedSubnet, setSelectedSubnet] = useState<{groupByType:'', label:'', value:''}[]>([]);
  const [selectedHostedZone, setSelectedHostedZone] = useState({ value: "", label: "" });

  const [fieldError, setFieldError] = useState<{isValid:boolean, message:string}[]>([]);

  const disallowedAZs:any = {
    "us-east-1": "use1-az3",
    "us-west-1": "usw1-az2",
    "ca-central-1": "cac1-az3"
  };
  //const cloudProvider:SelectItem[] = [{label:'aws', value:'aws'},{label:'gcp', value:'gcp'}];
  //const [cloudProvider,setCloudProvider] = useState('aws');
  
  // useEffect to fetch atmos account data
  useEffect(() => {
    fetchAtmosAccountData();
  }, []);

  useEffect(() => {
    // Only show error if required, rawErrors exist, and nothing is selected
    //setErrorState(required && rawErrors && selectedHostedZone.length === 0);

    console.log("testing")
  }, [required, rawErrors, selectedSubnet]);


  // Use useEffect to do something when the selected atmos value changes
  useEffect(() => {
    setSelectedVPC({
      groupByRegion:'', 
      label:'', 
      value:'', 
      regionShortName: '', 
      cidrBlock:''
    });

    setSelectedHostedZone({
      label: "",
      value: "",
    });

    setHasAtmosAccountChanged(true);
    setHasVPCChanged(true);
    
  }, [selecteAtmosAccount.name]);

  useEffect(() => {
    setSelectedSubnet([]);
    setHasVPCChanged(true);
    
  }, [selectedVPC.label]);

  const changed = (value: any) => {
    if (value) {
      //this is going to be broadcasted inside template and will be available as an outcome, can be accessed like parameter.[whatevername].split(:)
      onChange(value)
      //     value.atmosName + ":" + value.atmosAccountId + "!" + value.vpcValue + "~" +  value.vpcAccountId + "*" + value.vpcRegion + "^" + value.vpcRegionShortName + "#" + value.vpcCidrBlock + "?" + privateSubnet + ">" + publicSubnet
      // );
    }
  };

  const onSelectAtmosAccount = useCallback(
    (_: any, value: any) => {
      setSelecteAtmosAccount({name:value.name, accountId:value.accountId.toString()});
      changed({ atmosName: value.name || '', 
                atmosAccountId: value.accountId || '', 
                vpcValue: '',
                vpcAccountId: "", 
                vpcRegion:'', 
                vpcRegionShortName: '',
                vpcCidrBlock:'', 
                privateSubnet:[], 
                publicSubnet:[], 
                hostedZoneId:'',
                hostedZoneValue:'',
                error:[],
              });
    },
    [changed]
  );
  const onSelectVPC = useCallback(
    (_: any, value: any) => {
      setSelectedVPC(value);
      changed(value);
      changed({ atmosName: selecteAtmosAccount.name, 
                atmosAccountId: selecteAtmosAccount.accountId, 
                hostedZoneId:selectedHostedZone.label,
                hostedZoneValue:selectedHostedZone.value,
                vpcValue: value.value, 
                vpcAccountId: value.label,  
                vpcRegion:value.groupByRegion,
                vpcRegionShortName:value.regionShortName, 
                vpcCidrBlock:value.cidrBlock,
                privateSubnet:[],
                publicSubnet:[],
                error:fieldError,
               });
    },
    [changed]
  );

  const onSelectSubnet = useCallback(
    (_: any, value: any) => {
      setSelectedSubnet(value);
      changed(value);
      const validationResult = subnetValidator(value);
      const localFieldErrors = !validationResult.isValid ? [validationResult]:[]
      setFieldError(localFieldErrors);
      changed({ atmosName: selecteAtmosAccount.name, 
                atmosAccountId: selecteAtmosAccount.accountId, 
                hostedZoneId:selectedHostedZone.label,
                hostedZoneValue:selectedHostedZone.value,
                vpcValue: selectedVPC.value, 
                vpcAccountId: selectedVPC.label,  
                vpcRegion:selectedVPC.groupByRegion,
                vpcRegionShortName:selectedVPC.regionShortName, 
                vpcCidrBlock:selectedVPC.cidrBlock,
                //privateSubnet:value.filter( (subnet:any) => subnet.groupByType === "Private").map((subnet:any) => subnet.value),
                privateSubnet:value.filter( (subnet:any) => subnet.groupByType === "Private").map((subnet:any) => { return {az: subnet.az, id:subnet.value};}),
                publicSubnet:value.filter( (subnet:any) => subnet.groupByType === "Public").map((subnet:any) => { return {az: subnet.az, id:subnet.value};}),
                //publicSubnet:value.filter( (subnet:any) => subnet.groupByType === "Public").map((subnet:any) => subnet.value),
                error:localFieldErrors,
               });
    },
    [changed]
  );

  
  const onSelectHostedZone = useCallback(
    (_: any, value: any) => {
      setSelectedHostedZone(value);
      //super calling
      changed(value);
      changed({ atmosName: selecteAtmosAccount.name, 
                atmosAccountId: selecteAtmosAccount.accountId, 
                vpcValue: selectedVPC.value, 
                vpcAccountId: selectedVPC.label,  
                vpcRegion:selectedVPC.groupByRegion, 
                vpcRegionShortName:selectedVPC.regionShortName, 
                vpcCidrBlock:selectedVPC.cidrBlock,
                privateSubnet:selectedSubnet.filter( (subnet:any) => subnet.groupByType === "Private").map((subnet:any) => subnet.label),
                publicSubnet:selectedSubnet.filter( (subnet:any) => subnet.groupByType === "Public").map((subnet:any) => subnet.label),
                hostedZoneName:value.label,
                hostedZoneId:value.value,
                error:fieldError,
              });
    },
    [changed]
  );


  const subnetValidator = (data:any)  => {

      if (data.length < 2) {
          return { isValid: false, message: "Please select at least two subnets. A minimum of two subnets is required for this configuration." };
      }
      
      const uniqueAZs = new Set(data.map( (item:any) => item.az));
      if (uniqueAZs.size < 2) {
          return { isValid: false, message: "The selected subnets must be in two different Availability Zones (AZs). Please adjust your selection to ensure each subnet is in a unique AZ." };
      }

      return { isValid: true, message: "Subnet Validation passed." };
  }
  

  const onSubnetTextFieldFocus = () => {
    //Check if API data has already been fetched for VPC's
    if (!subnetEntityData || hasVPCChanged) {
      // Fetch data if not already fetched
      setHasVPCChanged(false);
      fetchSubnetData();
    }
  };

  const onHostedZoneTextFieldFocus = () => {
    //Check if API data has already been fetched for VPC's
    if (!hostedZoneEntityData || hasAtmosAccountChanged) {
      // Fetch data if not already fetched
      setHasAtmosAccountChanged(false);
      fetchHostedZoneData();
    }
  };

  const onVPCTextFieldFocus = () => {
    //Check if API data has already been fetched for VPC's
    if (!vpcEntityData || hasAtmosAccountChanged) {
      // Fetch data if not already fetched
      setHasAtmosAccountChanged(false);
      fetchVPCData();
    }
  };


  const fetchAtmosAccountData = async () => {
    try {

      let tempEntityRef = [{name:'', accountId:''}];

      const response: any = await catalogApi.getEntities({
          filter: {
            kind: "resource",
            "spec.type": "atmos-account"
          },
        });
  
      tempEntityRef = response?.items.map((e: any) => {
        return {
          name:e.metadata.name,
          accountId:e.spec.tags.AccountId,
        }
      });

      setEntityAtmosRefs(tempEntityRef);

      setSelecteAtmosAccount({
        name: "",
        accountId: "",
      });

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const fetchVPCData = async () => {
    try {

      let response: any = [];
      let tempEntityRef = [{groupByRegion:'', label:'', value:'', regionShortName: '', cidrBlock:''}];

      //if(cloudProvider == 'aws')
      {
        response = await catalogApi.getEntities({
            filter: {
              kind: "resource",
              "spec.type": "atmos-vpc",
              "spec.dependsOn": `resource:${selecteAtmosAccount.name}`,
            },
          });
        
        tempEntityRef = response?.items.map((e: any) => {
          return {
            groupByRegion: e.spec?.tags?.Region,
            label: e.spec?.tags?.Name,
            value: e.metadata.name,
            regionShortName: generateShortRegionName(e.spec.tags?.Region),
            cidrBlock: e.spec?.tags?.cidrBlock,
          };
        });

        // autocomplete component require array to be sorted for its grouping feature
        tempEntityRef.sort((a:any, b:any) => {
          const nameComparison = a.groupByRegion.localeCompare(b.groupByRegion);
          return nameComparison;
        });
      }
        

      setEntityVPCRefs(tempEntityRef);

      setVPCEntityData(response);

      setSelectedVPC({
          groupByRegion:'', 
          label:'', 
          value:'', 
          regionShortName: '', 
          cidrBlock:''
      });
      setHasVPCChanged(true);

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const fetchHostedZoneData = async () => {
    try {

      let response: any = [];
      let tempEntityRef = [{groupByType:'', label:'', value:''}];

      //if(cloudProvider == 'aws')
      {
        response = await catalogApi.getEntities({
            filter: {
              kind: "resource",
              "spec.type": "atmos-hostedzone",
              "spec.dependsOn": `resource:${selecteAtmosAccount.name}`,
            },
          });
        
        tempEntityRef = response?.items.map((e: any) => {
          return {
            groupByType: (e.spec?.tags?.PrivateZone).toLowerCase() === "true" ? "Private":"Public",
            label: e.spec?.tags?.HostedZoneId.split('/')[2],
            value: e.spec?.tags?.HostedZoneName,
          };
        });

      }
      
      tempEntityRef.sort((a:any, b:any) => {
        const nameComparison = a.groupByType.localeCompare(b.groupByType);
        return nameComparison;
      });

      setEntityHostedZoneRefs(tempEntityRef);

      setHostedZoneEntityData(response);

      setSelectedHostedZone({
        label: "",
        value: "",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const fetchSubnetData = async () => {
    try {

      let response: any = [];
      let tempEntityRef = [{groupByType:'', label:'', value:'', cidrBlock:'', az:'', azId:'', azRegion:''}];

      //if(cloudProvider == 'aws')
      {
        response = await catalogApi.getEntities({
            filter: {
              kind: "resource",
              "spec.type": "atmos-subnet",
              "spec.dependsOn": `resource:${selectedVPC.value}`,
            },
          });
        
        tempEntityRef = response?.items.map((e: any) => {
          return {
            groupByType: (e.spec?.tags?.SubnetName.includes("private"))? "Private":"Public",
            label: e.spec?.tags?.SubnetName,
            value: e.metadata.name,
            cidrBlock: e.spec?.tags?.cidrBlock,
            az:e.spec?.tags?.AvailabilityZone,
            azId:e.spec?.tags?.AvailabilityZoneId,
            azRegion:e.spec?.tags?.Region,
          };
        });
        
        // filter subnet with minimum of 6 IP addresses (cidr block <= 27)
        tempEntityRef = filterSubnetsByIPAdressAvailability(tempEntityRef);
        tempEntityRef = filterSubnetsByAllowedAvailabilityZones(tempEntityRef);

        // autocomplete component require array to be sorted for its grouping feature
        tempEntityRef.sort((a:any, b:any) => {
          const nameComparison = a.groupByType.localeCompare(b.groupByType);
          return nameComparison;
        });
      }
        
      setEntitySubnetRefs(tempEntityRef);

      setSubnetEntityData(response);

      setSelectedSubnet([]);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

 // Function to filter subnets
  const filterSubnetsByAllowedAvailabilityZones = (subnets:any) => {
    return subnets.filter((subnet:any) => {
      const region = subnet.azRegion;
      const az = subnet.azId;
      return disallowedAZs[region] !== az; // Include only subnets NOT in disallowed AZs
    });
  }

  const filterSubnetsByIPAdressAvailability = (subnets:any) => {
    // Filter the array to include only valid CIDR blocks
    return subnets.filter( (subnet:any) => {
        const [, prefixLength] = subnet.cidrBlock.split('/');
        const prefix = parseInt(prefixLength, 10);
        return prefix <= 27; // Keep only those with prefix <= 27
    });
  }

  
  const generateShortRegionName = (awsRegionName:string) => {
    return awsRegionName
      .replace(/-/g, "")         // Remove hyphens
      .replace("central", "c")   // Replace "central" with "c"
      .replace("east", "e")      // Replace "east" with "e"
      .replace("north", "n")     // Replace "north" with "n"
      .replace("south", "s")     // Replace "south" with "s"
      .replace("west", "w");     // Replace "west" with "w"
  }

  return (
    <>

      <FormControl
        margin="normal"
        required={required}
        error={rawErrors &&  rawErrors.length > 0 && !formData}
      >

      <Autocomplete
          id={idSchema?.$id}
          value={selecteAtmosAccount}
          getOptionLabel={(atmos: any) => atmos?.name}
          options={(entityAtmosRefs || [])}
          onChange={onSelectAtmosAccount}
          autoSelect
          freeSolo={false}
          renderInput={params => (
            <TextField
              name="Atmos"
              {...params}
              label="Atmos"
              margin="normal"
              helperText="Enter Atmos Account (Name of the Atmos Account the cluster will be deployed into.)"
              //onFocus={onAtmosTextFieldFocus}
              variant="outlined"
              required={required}
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>

      <FormControl
        id="hostedZone"
        margin="normal"
        required={required}
        error={rawErrors && rawErrors.length > 0 && !formData}
      >
        <Autocomplete
          id={idSchema?.$id}
          value={selectedHostedZone}
          groupBy={(zone: any) => zone?.groupByType}
          getOptionLabel={(zone: any) => zone?.value}
          options={entityHostedZoneRefs}
          onChange={onSelectHostedZone}
          freeSolo={false}
          renderInput={(params) => (
            <TextField
              name="hostedZonetf"
              onFocus={onHostedZoneTextFieldFocus}
              {...params}
              label="HostedZone"
              margin="normal"
              helperText="The HostedZone"
              variant="outlined"
              required={required}
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>
     

      <FormControl
        margin="normal"
        required={required}
        error={rawErrors && rawErrors.length > 0 && !formData}
      >
        <Autocomplete
          id={idSchema?.$id}
          value={selectedVPC}
          groupBy={(vpc: any) => vpc?.groupByRegion}
          getOptionLabel={(vpc: any) => vpc?.label}
          options={entityVPCRefs}
          onChange={onSelectVPC}
          freeSolo={false}
          renderInput={(params) => (
            <TextField
              name="vpc"
              onFocus={onVPCTextFieldFocus}
              {...params}
              label="VPC"
              margin="normal"
              helperText="The VPC"
              variant="outlined"
              required={required}
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>

      <FormControl
        id={idSchema?.$id}
        margin="normal"
        required={required}
        error={rawErrors && rawErrors.length > 0 && selectedSubnet.length === 0}
      >
        <Autocomplete
          multiple
          id={idSchema?.$id}
          value={selectedSubnet}
          groupBy={(subnet: any) => subnet?.groupByType}
          getOptionLabel={(subnet: any) => subnet?.label}
          options={entitySubnetRefs}
          onChange={onSelectSubnet}
          freeSolo={false}
          renderInput={(params) => (
            <TextField
              name="subnettf"
              onFocus={onSubnetTextFieldFocus}
              {...params}
              label="Subnets"
              margin="normal"
              helperText="The Subnets"
              variant="outlined"
              required={selectedSubnet.length === 0}
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>

    </>
  );
};

export const validateSubnetValidation = (
  value: any,
  validation: FieldValidation,
) => {

  if(value?.error?.length > 0)
      validation.addError(value.error[0].message)
}
