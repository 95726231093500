import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  configApiRef,
  fetchApiRef,
  identityApiRef
} from '@backstage/core-plugin-api';
import { argoHealthApiRef, ArgoHealthService } from './api/ArgoHealthService';
import { vidpCLusterHealthApiRef, VIDPClusterHealthService } from './api/VIDPClusterHealthService';

export const argoHealthPlugin = createPlugin({
  id: 'argo-health',
  apis: [
    createApiFactory({
      api: argoHealthApiRef,
      deps:{
        config: configApiRef,
        fetchApi: fetchApiRef,
        identityApi:identityApiRef,
      },
      factory:({config, fetchApi, identityApi}) => new ArgoHealthService({config,fetchApi,identityApi})
    }),
    createApiFactory({
      api: vidpCLusterHealthApiRef,
      deps:{
        config: configApiRef,
        fetchApi: fetchApiRef,
        identityApi:identityApiRef,
      },
      factory:({config, fetchApi, identityApi}) => new VIDPClusterHealthService({config,fetchApi,identityApi})
    })
  ],
});

export const EntityArgoHealthCard = argoHealthPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
        import('./components/overview').then(
          m => m.ArgoOverviewCard,
        ),
    },
  }),
);

export const EntityArgoHealthPage = argoHealthPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
        import('./components/detail').then(
          m => m.DeploymentHealthDetail,
        ),
    },
  }),
);

export const EntityVIDPClusterHealthPage = argoHealthPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
        import('./components/cluster-health').then(
          m => m.VIDPClusterHealthCard,
        ),
    },
  }),
);